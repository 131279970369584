let echarts = require('echarts/lib/echarts')
var myColor=  
['#8693f3','#ffa897','#bc8dee','#00d488','#89c3f8', '#f1bb4c','#3bafff','#afa3f5', '#3feed4','#e56ff4',
'#60aefe','#FC619D', '#FF904D', '#48BFE3','#ff7a63']
let canvasJs={
    jobBar(yAxisData,seriesData,itemsum){
         //寻求数组最大值或者所有项总和（itemsum=1）
        let max=itemsum?100:Math.max.apply(null,seriesData)
        let options={
            grid: {
                top: '0',
                left: '0',
                right: '4.75%',
                bottom: '0',
                containLabel: true
              }, 
              yAxis: [{
                type: 'category',
                data: yAxisData,
                inverse: true,
                axisTick: {
                  show: false
                },
                axisLabel: {
                  margin: 20,
                  textStyle: {
                    fontSize: 12,
                    color:"#999"
                  },
                  
                },
                axisLine: {
                  show: false
                }
              }],
              xAxis: [{
                type: 'value',
                axisLabel: {
                  show: false
                },
                axisLine: {
                  show: false
                },
                splitLine: {
                  show: false
                }
              }],
              series: [{
                type: 'bar',
                barWidth: 14,
                
                data: seriesData,
                label: {
                  normal:{show:true, position:"right",formatter: '{c}%',}
                },
                
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                      offset: 0,
                      color: '#409eff' // 0% 处的颜色
                    }, {
                      offset: 1,
                      color: '#409eff' // 100% 处的颜色
                    }], false),
                    barBorderRadius: 14
                  }
                }
              }, 
              {
                type: "bar",
                barWidth: 14,
                xAxisIndex: 0,
                barGap: "-100%",
                data: [max,max,max,max,max,max,max,max,max,max,max,max,],
                itemStyle: {
                  normal: {
                    color: "#EBEEF5",
                    barBorderRadius: 14
                  }
                },
                zlevel: -1
              }
            ]
        }
        return options
    },
    interviewPie(data){
        let options,max=0;
        data.forEach(e=>{
          max+=e.value
        })     
        let titleArr= [], seriesArr=[];
        let colors=[['#389af4', '#dfeaff'],['#ff8c37', '#ffdcc3'],['#ffc257', '#ffedcc'], ['#fd6f97', '#fed4e0'],['#a181fc', '#e3d9fe']]
        data.forEach(function(item, index){
           
            titleArr.push(
                {
                    text:item.name,
                    left: index * 20 + 20 +'%',
                    top: '58%',
                    textAlign: 'center',
                    textStyle: {
                        fontWeight: 'normal',
                        fontSize: '12',
                        color: colors[index][0],
                        textAlign: 'center',
                    },
                }        
            );
            seriesArr.push(
                {
                    name: item.name,
                    type: 'pie',
                    clockWise: false,
                    radius: [50, 60],
                    itemStyle:  {
                        normal: {
                            color: colors[index][0],
                            shadowColor: colors[index][0],
                            shadowBlur: 0,
                            label: {
                                show: false
                            },
                            labelLine: {
                                show: false
                            },
                        }
                    },
                    hoverAnimation: false,
                    center: [index * 20 + 20 +'%', '50%'],
                    data: [{
                        value: item.value,
                        label: {
                            normal: {
                                formatter: function(params){
                                    return params.value;
                                },
                                position: 'center',
                                show: true,
                                textStyle: {
                                    fontSize: '26',
                                    fontWeight: 'bold',
                                    color: colors[index][0]
                                }
                            }
                        },
                    }, {
                        value: max,
                        name: 'invisible',
                        itemStyle: {
                            normal: {
                                color: colors[index][1]
                            },
                            emphasis: {
                                color: colors[index][1]
                            }
                        }
                    }]
                }    
            )
        });
        
            
        options = {
            backgroundColor: "#fff",
            title:titleArr,
            series: seriesArr
        }
        return options;
    },
    companyCityPie(legendData,seriesData){
      
      let options={
        color:myColor,
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        
        series: [
            {
                name: '城市',
                type: 'pie',
                radius: ['45%', '60%'],
                avoidLabelOverlap: false,
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '30',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: seriesData
            }
        ]
      }
      return options
    },
    teaNumBar(xData,yData){
      let options={
        color: ['#8693f3'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            }
        },
        grid: {
          top:'40',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data:xData,
                axisTick: {
                    alignWithLabel: true
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name:'个'
            }
        ],
        series: [
            {
                name: '对接企业数量',
                type: 'bar',
                barWidth: '60%',
                data: yData,
                label: {
                  show: true,
                  formatter: '{c}'
              },
            }
        ]
      }
      return options
    }
}

export {canvasJs}
